import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-common-form-config-mixins",
    props: {
        modelValue: {
            type: [String, Number, Array, Object],
            default: (): any => {
                return null;
            },
        },
    },
    emits: ["update:modelValue"],
})
export default class ComponentConfigMixins extends BaseFunction {
    private modelValue: any;
    get _value() {
        return this.modelValue;
    }
    set _value(val) {
        this.$emit("update:modelValue", val);
    }
    get _placeholder() {
        return this._value.placeholder;
    }
    set _placeholder(ver) {
        if (this.$tools.isEmpty(ver)) {
            delete this._value.placeholder;
        } else {
            this._value.placeholder = ver;
        }
    }
}
